import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Wrapper, CardContainerView2, GridCardView, GridCardPeopleView } from './styles';
import { Row } from '../PeopleListView/styles';
import { ImageUrl } from '../../utils/constants';
import _ from "lodash";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ParticipantLocationPopup from '../NewEmployeePopupV2/participantsLocation';
import LazyImage from '../common/LazyImage/LazyImage';
import CommonButton from '../common/CommonButton/CommonButton';
import { ViewProfileIcon, defaultPeopleIcon, wellnessChampionCardIcon } from "../../utils/icons";
class PeopleGridView extends Component {
  constructor() {
    super();
    this.state = {
      userId: null,
      initiativeId: null,
      initiativeName: null,
      employeeList: [],
      selectedParticipant:[],
      showNote: false,
      onHover: -1,
      showFallback:false,
    };
  }

  componentDidMount() {
    const arra = []
    const { employeeList } = this.props;
    employeeList && employeeList.length && employeeList.map((obj) => {
      obj.select = false;
      arra.push(obj)
    })
    this.setState({ employeeList: arra })
    localStorage.setItem("selectedEmployeeList", JSON.stringify([]))
  }

  onHoverMouse = (id) => {
    this.setState({
      onHover: id
    })
  };

  onLeaveHoverMouse = () => {
    this.setState({
      onHover: -1
    })
  };


  onEnterMouse = (uid, id, name) => {
    this.setState({
      userId: uid,
      initiativeId: id,
      initiativeName: name
    })
  };

  onLeaveMouse = () => {
    this.setState({
      userId: null,
      initiativeId: null
    })
  };

  activeTick = (index) => {
    let prev = this.state.employeeList;
    let newData = this.props.employeeList;
    let totalData = prev.concat(newData)
    let objIndex = totalData.findIndex(((obj) => obj.uid == index));
    const prevSelect = totalData[objIndex].select;
    totalData[objIndex].select = !prevSelect;
    let selectedArray = totalData.filter((obj) => obj.select === true)
    let unique = selectedArray.filter((item, index) => selectedArray.indexOf(item) === index);
    this.setState({
      employeeList: totalData,
      selectedParticipant: unique
    });
    localStorage.setItem("selectedEmployeeList", JSON.stringify(selectedArray))

  }

  fallBack = (e) => {
    e.target.src = '/public/images/default.png'
  }

  goToPersonalProfile = (employeeId) => {
    const { history } = this.props;
    history.push(`/company/people/${employeeId}/personal-profile`)
  }


  capitalizeFirstLetter = (name) => {
    if (!name) return "";
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  };

  render() {
    const { showEmployeeDetails, selectedEmployee, sendFriendRequestPost, searchAmigo, t, show, plp, employeeList } = this.props;
    const { userId, initiativeId, initiativeName, onHover } = this.state;
    let uidArray = [];
    this.state.selectedParticipant.map((obj) => {
      uidArray.push(obj.uid)
    })
    
    return (
      <Wrapper marginTop={"0px"} content="1">
        {
          !(_.isNull(employeeList)) && !(_.isUndefined(employeeList)) && employeeList.length > 0 ?
            employeeList.map((employee, index) => (
              <CardContainerView2 key={index} marginRight={(index + 1) % 4 === 0 ? "0px" : "25px"} onClick={() => showEmployeeDetails(employee.uid)} active={selectedEmployee === employee.uid} deleted={employee.is_user_deleted && !searchAmigo ? 1 : 0} >
                {searchAmigo?<GridCardView active={selectedEmployee === employee.uid} deleted={employee.is_user_deleted && !searchAmigo}>
                  <div className="wellness-champion">
                    {employee.is_user_deleted && !searchAmigo ? <div>{t("User will be removed end of Month")}</div> : employee.is_champion ? <div>{t("Wellness Champion")}</div> : ""}
                  </div>
                  <div style={{ display: 'flex', width: '100%' }}>
                    <div className="profile">
                      <LazyImage onError={this.fallBack} src={`${ImageUrl}/${employee.profile_image}`}/>
                    </div>
                    <div style={{ width: '20%', }}>
                      {show && <div style={{ width: '24px', height: '24px', border: '1px solid #005C87', borderRadius: '21px',display:"flex",justifyContent:"center",alignItems:"center" }} onClick={() => this.activeTick(employee.uid)} >
                        {uidArray.includes(employee.uid) && <img src={ImageUrl + '/ChallengeDetailsScreen/updatedCheckmark.svg'}></img>}
                      </div>}
                    </div>
                  </div>
                  <div className="name">
                    {t(employee.fname)} {t(employee.lname)}
                  </div>
                  {employee.department ? <div className="department">
                    {t(employee.department)}
                  </div> :<div className='department'>{""}</div>}
                  <div className="wellness-card">
                    {employee.interests && employee.interests.map((Wellness, index) => (
                      index < 5 &&
                      <div key={index} onMouseEnter={() => this.onEnterMouse(employee.uid, Wellness.interest_id, Wellness.interest_name)} onMouseLeave={() => this.onLeaveMouse()}>
                        <LazyImage src={`${ImageUrl}/${Wellness.interest_icon}`} />
                        {userId === employee.uid && initiativeId === Wellness.interest_id &&
                          <div className="hover-card">
                            <div> {t(initiativeName)}</div>
                          </div>}
                      </div>))}
                  </div>
                  {searchAmigo ?
                    <div className="mutual-buddies">
                      {employee.mutual_friends && employee.mutual_friends.length > 0 ?
                        <div>
                          <div className="text">{t("Mutual Buddy")}</div>
                          <div className="profile-image-card">
                            {employee.mutual_friends && employee.mutual_friends.length > 0 && employee.mutual_friends.map((mutual, index) =>
                              index < 2 &&
                              <img onError={this.fallBack} src={`${ImageUrl}/${mutual.profile_image}`} key={index} />
                            )}
                            {employee.mutual_friends && employee.mutual_friends.length > 2 &&
                              <div className="count">{employee.mutual_friends.length - 2}+</div>}
                          </div>
                        </div> :
                        null}
                    </div>
                    :
                    <div className="mutual-buddies">
                      <div>
                        {
                          employee.total_global_points ?
                            <div className='no-text'>
                              {`${employee.total_global_points} Pts`}
                            </div> :null
                        }
                      </div>
                    </div>
                  }
                  <div className="button-cotainer" style={{marginTop: employee.mutual_friends && employee.mutual_friends.length == 0?"65px":"0"}}>
                    {!searchAmigo ?
                      // <RequestButton
                      //   onClick={() => this.goToPersonalProfile(employee.uid)}
                      //   background='#69C2FF'>{t("View Profile")}
                      // </RequestButton>
                      <CommonButton
                        onClick={() => this.goToPersonalProfile(employee.uid)}
                        btnType={"square"}
                        styles={{background:'#69C2FF', cursor:'pointer'}}
                        title={t("View Profile")}
                      />
                      :
                      employee.friend === 0 ?
                        // <RequestButton onClick={() => sendFriendRequestPost(employee.uid)} background="#FD7175">
                        //   {t("Add Buddy")}
                        // </RequestButton> 
                        <CommonButton
                          onClick={() => sendFriendRequestPost(employee.uid)}
                          btnType={"square"}
                          styles={{cursor:'pointer'}}
                          title={t("Add Buddy")}
                        />
                        :
                        employee.friend === 1 ?
                          // <RequestButton background="rgb(159, 201, 137)" cursor>
                          //   {t("Buddies")}
                          // </RequestButton>
                          <CommonButton
                            onClick={() => sendFriendRequestPost(employee.uid)}
                            styles={{background:"rgb(159, 201, 137)"}}
                            btnType={"square"}
                            title={t("Buddies")}
                          />
                          : employee.friend === 2 ?
                            // <RequestButton background="rgb(246, 180, 121)">
                            //   {t("Pending")}
                            // </RequestButton>
                            <CommonButton
                              styles={{background:"rgb(246, 180, 121)"}}
                              btnType={"square"}
                              title={t("Pending")}
                            /> 
                            : ""}
                  </div>
                </GridCardView>:
                  <GridCardPeopleView active={selectedEmployee === employee.uid} deleted={employee.is_user_deleted && !searchAmigo}
                    background={"#007AB1"} cursor={employee.is_user_deleted}
                  >
                    <div className='wrapperData'onMouseEnter={() => this.onHoverMouse(employee.uid)} onMouseLeave={() => this.onLeaveHoverMouse()}>
                      {employee && onHover === employee.uid &&
                      <div className='imageBackground'  >
                        <div className="view-button" onClick={employee.is_user_deleted?"":  () => this.goToPersonalProfile(employee.uid)}><div style={{marginRight:'10px'}}> <ViewProfileIcon/></div>{t("View Profile")}</div>
                      </div>
                      }
                    
                      {employee.is_user_deleted && !searchAmigo ? <div  className="wellness-champion">{t("User will be removed end of Month")}</div> : employee.is_champion ? <div style={{display:'flex',justifyContent:'flex-start',  position: 'absolute', zIndex:'1', margin:'10px'}}>{wellnessChampionCardIcon()}</div> : ""}
                      <div style={{ display: 'flex', width: '100%' }}>
                        <div className="profile">
                          {
                            employee.profile_image ==='profile-Image/default.png' ? 
                              <div style={{width: '80px', height:'80px',background:'rgba(0, 92, 135, 0.10)',borderRadius:'80px', display:'flex', justifyContent:'center',alignItems:'center'}}> { defaultPeopleIcon()} </div> :  <LazyImage src={`${ImageUrl}/${employee.profile_image}`} /> 
                          }
                        </div>
                        <div style={{ width: '20%', zIndex:"100"}}>
                          {show && <div style={{ width: '24px', height: '24px', border: '1px solid #005C87', borderRadius: '21px', zIndex:"100",display:"flex",justifyContent:"center",alignItems:"center" }} onClick={() => this.activeTick(employee.uid)} >
                            {uidArray.includes(employee.uid) && <img src={ImageUrl + '/ChallengeDetailsScreen/updatedCheckmark.svg'}></img>}
                          </div>}
                        </div>
                      </div>
                      <div className="name">
                        {t(this.capitalizeFirstLetter(employee.fname))} {t(this.capitalizeFirstLetter(employee.lname))}
                      </div>
                      {employee.department ? <div className="department">
                        {t(employee.department)}
                      </div> :<div className='department'> - </div>}
                      {employee.location ? <div className="location">
                        {t(employee.location)}
                      </div> :<div className="location"> - </div>}</div>
                    <div className="wellness-card">
                      {employee.interests && employee.interests.map((Wellness, index) => (
                        index < 5 &&
                      <div key={index} onMouseEnter={() => this.onEnterMouse(employee.uid, Wellness.interest_id, Wellness.interest_name)} onMouseLeave={() => this.onLeaveMouse()}>
                        <LazyImage src={`${ImageUrl}/${Wellness.interest_icon}`} />
                        {userId === employee.uid && initiativeId === Wellness.interest_id &&
                          <div className="hover-card">
                            <div> {t(initiativeName)}</div>
                          </div>}
                      </div>))}
                    </div>
                  </GridCardPeopleView>}
              </CardContainerView2>
            ))
            :
            <Row disableCursor={1} align={1} upperBoxShadow="2px solid rgba(0,0,0,0.08)">
              {t("No Employee")}
            </Row>
        }
        {!searchAmigo &&
          <ParticipantLocationPopup companyId={plp.companyId} fetchEditLocationApi={plp.fetchEditLocationApi}
            show={plp.show} departmentData={plp.departmentData} handleShowNote={plp.handleShowNote} data={plp.data} showModalPopUp={plp.showModalPopUp} selectedParticipant={this.state.selectedParticipant} companyAuthDetails={plp.companyAuthDetails}/>
        }
      </Wrapper>)
  }
}

PeopleGridView.propTypes = {
  employeeList: PropTypes.array.isRequired,
  showEmployeeDetails: PropTypes.func.isRequired,
  selectedEmployee: PropTypes.any,
  searchAmigo: PropTypes.bool,
  history: PropTypes.object,
  t: PropTypes.func,
  sendFriendRequestPost: PropTypes.func,
  show: PropTypes.bool,
  plp:PropTypes.object
};

const mapStateToProps = (state) => ({
  sentRequest: state.social.sentRequest
});

export default connect(mapStateToProps, null)(withTranslation()(PeopleGridView));
