import React from 'react';
import PropTypes from 'prop-types';
import {LevelText, Wrapper, CardContainer, ImageWrapper, EmployeeInformation,
  AoiBarPeopleGridViewV2, CardContainerHearder,UserDetail,DepartmentData} from './styles';
import { EmployeeName, Row } from '../PeopleListView/styles';
import { Button } from '../PeopleHomeV2/styles';
import Image from '../Image';
import { ImageUrl } from '../../utils/constants';
import _ from "lodash";
import {withTranslation} from 'react-i18next';
import { connect } from 'react-redux';
import { sendFriendRequest} from '../../redux/actions';

const PeopleGridView = ({employeeList,showEmployeeDetails,selectedEmployee,searchAmigo,sendFriendRequest, t}) => (
  <Wrapper marginTop={"5px"}>
    {
      !(_.isNull(employeeList)) && !(_.isUndefined(employeeList)) && employeeList.length > 0 ?
        employeeList.map((employee, index) => (
          <CardContainer key={index} onClick={() => showEmployeeDetails(employee.uid)} 
            active={selectedEmployee === employee.uid} newDeleted={employee.is_user_deleted ? 1 : 0} deleted={employee.is_user_deleted && !searchAmigo}>
            <CardContainerHearder onClick={() => showEmployeeDetails(employee.uid)}   active={selectedEmployee === employee.uid}/>
            <div>
              <ImageWrapper top={employee.is_champion}>
                <Image image={employee.profile_image} />
              </ImageWrapper>
              <div className="headerGrid"> 
                {
                  (employee.is_champion) ?
                    <LevelText color="#FBFCFC" height={"40px"}>
                      <img src="/public/images/PeoplePage/Ribbon_img.png" />
                      <div>{'Wellness Champion'}</div>
                    </LevelText>
                    :
                    <LevelText color="#FBFCFC" height={"40px"} >{""}</LevelText>
                }
                {
                  (selectedEmployee === employee.uid) ?
                    <EmployeeName  color="#3A3A3A">{employee.fname} { employee.lname}
                    </EmployeeName>
                    :
                    <EmployeeName>{employee.fname} { employee.lname}</EmployeeName>
                }
                {
                  employee.department ? 
                    <DepartmentData>{employee.department}</DepartmentData>
                    :
                    <DepartmentData  value="hiddenFieldName" type="hidden">&nbsp; &nbsp; &nbsp;</DepartmentData>
                }
              </div>
              <AoiBarPeopleGridViewV2 active={selectedEmployee === employee.uid}>
                { employee.interests && employee.interests.map((Wellness, index) => (
                  index < 5 &&
                  <div key={index}>
                    <img src={`${ImageUrl}/${Wellness.interest_icon}`} />
                  </div>
                ))}
              </AoiBarPeopleGridViewV2>
              <EmployeeInformation />
              <UserDetail active={selectedEmployee === employee.uid}>
                <div className="employeeInfo" />
                <div className="challenge-user">
                  <div className="section4">{employee.total_global_points} {t("points")}</div>
                </div>
                <div className="body_col">
                  {
                    employee.friend === 0 ?
                      <Button profileColor={'profileColor'} newWidth={'newWidth'} fontFamily={'Rubik-Medium'}
                        onClick={() => sendFriendRequest( employee.uid, employee.request_status)}>add buddy</Button> :  employee.friend === 1 ? 
                        <Button  greenProfileColor={'GreenProfileColor'} newWidth={'newWidth'}  fontFamily={'Rubik-Medium'}>buddies</Button> : employee.friend === 2 ?
                          <Button  yellowProfileColor={'YellowProfileColor'} newWidth={'newWidth'}  fontFamily={'Rubik-Medium'}>pending</Button> :""
                  }
                </div>
              </UserDetail>
            </div>          
          </CardContainer>
        ))
        :
        <Row disableCursor={1} align={1} upperBoxShadow="2px solid rgba(0,0,0,0.08)">
          No Employee
        </Row>
    }
  </Wrapper>
);

PeopleGridView.propTypes = {
  employeeList: PropTypes.array.isRequired,
  showEmployeeDetails: PropTypes.func.isRequired,
  selectedEmployee: PropTypes.any,
  searchAmigo: PropTypes.bool,
  sendFriendRequest : PropTypes.func,
  history: PropTypes.object.isRequired,
  t: PropTypes.func
};

const mapStateToProps = (state) => ({
  sentRequest: state.social.sentRequest
});

const mapDispatchToProps = (dispatch) => ({
  sendFriendRequest: (data) => dispatch(sendFriendRequest(data))

})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PeopleGridView));
